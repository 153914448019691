.treatmentSelectionModalDiv {
    background: #fff;
    box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.1);   
    height: var(--treatment-selection-height);
    position: sticky !important;
    bottom: 0 !important;
    outline: none !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 1201 !important;
    margin-left: var(--admin-panel-side-menu-width);
    &>.treatmentSelectionContDiv {
        position: absolute;
        width: 100%;
        bottom: 0;
        display: grid;
        grid-template-columns: 6fr 2fr 2fr;
        outline: none !important;

        &>.treatmentSelectionFinalizeDiv {
            display: flex;
            font-family: 'OpenSansRegular';
            align-items: center;

            &>.treatmentSelectionImgDiv {
                width: 80px;
                height: 80;
                display: grid;
                place-items: center;
                margin-right: .3em;
                padding: 10px;

                @media only screen and (max-width: 1000px) {
                    width: 50px;
                    height: 50px;                
                }

                &>img {
                    object-fit: cover;
                    width: 80px;
                    height: 80px;
                    border-radius: 5px;

                    @media only screen and (max-width: 1000px) {
                        width: 50px;
                        height: 50px;   
                    }
                }
            }

            &>.treatmentSelectionInfoDiv {
                display: flex;
                flex-direction: column;
                &>span>.treatmentSelectedTitle {
                    font-family: 'OpenSansSemiBold';
                }

                @media only screen and (max-width: 1000px) {
                    font-size: 13px;              
                }
            }
        }

        &>.treatmentSelectionBookingDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        &>.treatmentSelectionActionDiv {
            display: grid;
            place-items: center;
            padding: .5em;
            
            &>.actionButtonBrown {
                width: 100%;
            }

            &>.actionButtonBrownDisabled {
                width: 100%;
            }
        }
    }

    &>.treatmentSelectionFullwidthDiv {
        height: 100%;
        grid-template-columns: 10fr !important;
    }
}

.treatmentSelectionModalAdminTodayDiv {
    display: none;
}

.treatmentDropdownDiv {
    padding: 20px;

    @media only screen and (max-width: 1000px) {
        font-size: 12px;
    }

    &>span {
        font-family: 'PoppinsRegular';

        &>span {
            font-family: 'PoppinsBold';
        }
    }
}

.tempBookingTableDiv {
    overflow-x: auto;

    &>.tempBookingTable {
        border: 1px solid #E2E2E2;
        border-collapse: collapse;
        width: 100%;

        @media only screen and (max-width: 1000px) {
            width: 200%;
        }
    
        &>tbody>tr {
            border: 1px solid #E2E2E2;
            &>th {
                border: 1px solid #E2E2E2;
                padding: 10px;
                font-family: 'OpenSansLight';
                word-break: break-word;
                width: 20%;

                @media only screen and (max-width: 1000px) {
                    font-size: 12px;
                }
            }
            &>.tempBookingTimerDiv {
                font-family: 'PoppinsBold';
                text-align: center;
            }
            &>.tempBookingDelete {
                text-align: center;
                
                &>.tempBookingDeleteIcon {
                    cursor: pointer;
                }
            }
            &>td {
                border: 1px solid #E2E2E2;
                padding: 10px;
                font-family: 'OpenSansLight';
                width: 20%;

                @media only screen and (max-width: 1000px) {
                    font-size: 12px;
                }
                &>.tempBookingDiv {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    font-family: 'OpenSansLight';
    
                    @media only screen and (max-width: 1000px) {
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                    }
    
                    &>img {
                        width: 50px;
                        height: 50px;
                        border-radius: 5px;
                        margin-right: 1em;
                        object-fit: cover;
    
                        @media only screen and (max-width: 1000px) {
                            width: 30px;
                            height: 30px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.treatmentSelectionBookingDisabledDiv {
    cursor: not-allowed !important;
    opacity: .5 !important;
}

.tempBookingInfo {
    font-family: 'OpenSansLight';
    font-size: 15px;

    @media only screen and (max-width: 1000px) {
        font-size: 12px;
    }
}