.filteredItemDiv {
    
    &>.product-filtered-item {
        text-align: center;
        margin: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        height: 24em;

        &>a {

            &>img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        
            &>.services-detail {
                margin-top: .1em;
                display: flex;
                font-size: 16px;
                justify-content: center;
                white-space: nowrap;
                font-family: "PoppinsRegular";
                color: #5d3b23;

                @media only screen and (max-width: 1000px) {
                    font-size: 12px;
                }
            }
        }

        &>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    
        &>.services-detail {
            margin-top: .1em;
            display: flex;
            font-size: 16px;
            justify-content: center;
            white-space: nowrap;
            font-family: "PoppinsRegular";
            color: #5d3b23;

            &>span {
                
                @media only screen and (max-width: 1000px) {
                    font-size: 12px;
                    word-break: break-all;
                }
            }
        }

    }

    &>.filtered-item {
        text-align: center;
        margin: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        height: 10em;
    
        &>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    
        &>.services-detail {
            margin-top: .1em;
            display: flex;
            
            justify-content: center;
            white-space: nowrap;
            font-family: "PoppinsRegular";
            color: #5d3b23;

            &>span {
                
                @media only screen and (max-width: 1000px) {
                    font-size: 12px;
                    word-break: break-all;
                }
            }
        }
    }
}


