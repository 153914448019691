.myAccountBookingDiv {

    height: fit-content;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>.rehitThreshold {
        height: 50px;
        width: 100%;
        background: yellowgreen;
    }

    &>.myAccountBookingCont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'OpenSansRegular';
        user-select: none;

        @media only screen and (max-width: 1000px) {
            width: 100vw;
        }

        &>.myAccountBookingEnd {
            margin: 1em;
            font-family: 'OpenSansRegular';
        }

        &>.myAccountButton {
            cursor: pointer;
            margin-top: 1em;
            padding: 10px 15px;
            background: #fff;
            opacity: 1;
            font-family: 'OpenSansRegular';
            border-radius: 5px;
            box-shadow: 5px 5px 5px rgba(0,0,0,.1);
            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover, &:focus {
                box-shadow: 0px 0px 0px rgba(0,0,0,.1);
            }
        }
    }

    &>.myAccountNoBookingCont {
        height: 100vh;
    }
}