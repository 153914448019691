.benefitsListContainer {
    width: 100%;
    padding: 50px 0;
    user-select: none;

    &>.benefitsDiv {
        width: 90%;
        margin: 0 auto;

        &>.benefitsTitle {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            font-family: "SourceSerifSemiBold";
            text-align: center;
            justify-content: center;
            margin-bottom: 40px;

            @media only screen and (max-width: 1000px) {
                width: 100%;
            }
        
            &>h1 {
                font-size: 40px;
                color: #5d3b23;
                text-transform: capitalize;

                @media only screen and (max-width: 1000px) {
                    font-size: 30px;
                }
            }
        }

        &>.benefitsContent {
            display: grid;
            grid-template-columns: repeat(6, 1fr); 
            gap: 1em;
            height: fit-content;

            &>.benefits {
                color: #5d3b23;
                animation: slideRight 15s linear infinite;
                overflow: hidden;
                height: fit-content;
                width: 100%; 
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @media only screen and (max-width: 1000px) {
                    width: 100px;                        
                }

                @keyframes slideRight {
                    0% { 
                        transform: translateX(-100vw);
                    }
                    100% { 
                        transform: translateX(100vw);
                    }
                }

                &>.benefitsDetails {
                    &>.detailsTitle {
                        display: flex;
                        text-align: center;
                        white-space: nowrap;
                        justify-content: center;
                
                        &>span {
                            font-size: 100%;
                            font-family: "PoppinsRegular";
                            letter-spacing: 0.5px;
                            white-space: nowrap;
                            word-break: break-all;
                        }
                
                        @media only screen and (max-width: 1000px) {
                            display: none;
                        }
                    }
                }
                
                &>.benefitsImage {
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    width: 50px; 
    
                    @media only screen and (max-width: 1000px) {
                        height: 100px;
                        width: 100px;                        
                    }
                    
                    &>img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}