.paymentDiv {
    width: 100vw;
    height: 100vh;
    margin-top: var(--header-height);
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 5fr 5fr;
    
    @media only screen and (max-width: 1000px) {
        grid-template-rows: auto auto;
        grid-template-columns: auto;
        height: fit-content;
    }

    &>.loading {
        width: 100vw;
        height: 100vh;
        display: grid;
        place-items: center;
    }

    &>.paymentOrderDetails {
        display: grid;
        place-items: center;
        font-family: 'OpenSansRegular';
        text-align: left;
        color: #5d3b23;
        width: 40vw;

        @media only screen and (max-width: 1000px) {
            width: 100vw;
        }

        &>.paymentOrderCont {
            height: fit-content;
            @media only screen and (max-width: 1000px) {
                display: grid;
                place-items: center;
                margin: 2em;
            }
            &>.paymentOrderTitle {
                width: 100%;
                font-family: 'SourceSerifRegular';
                text-align: left;
                font-size: 2em;
            }
    
            &>.paymentOrderGrandDiv {
                font-family: 'PoppinsBold';
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                &>.grandTotal {
                    margin-left: 1em;
                    font-family: 'PoppinsSemiBold';
                    font-size: 1.8em;
                }

                @media only screen and (max-width: 1000px) {
                    padding-bottom: 20px;
                }
            }
        }
    }

    &>.stripeDiv {
        display: grid;
        place-items: center;
        color: #5d3b23;

        &>.stripeContDiv {
            padding: 5em 1em;
            border-radius: 5px;

            &>.stripeContTitle {
                font-family: 'SourceSerifRegular';
                font-size: 2.2em;
            }
        }
    }

    .finalPrice {
        font-family: 'OpenSansBold';
    }

    .grandTotal {
        text-align: right;
    }
}