.qrCodeGeneratorDiv {
    cursor: pointer;

    &>.qrCodeCta {
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}

.qrCodeFullDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-family: 'PoppinsRegular';
    font-size: 13px;
}