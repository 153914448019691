button {
    background: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 2px;
    padding: 5px 10px;
}

.first-division {
    display: flex;
    flex-direction: column;
    margin: 0 5px 0.5rem 0;
    width: 100%;

    button {
        align-self: flex-start;
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        color: rgb(0, 208, 255);
        border: 1px solid rgb(0, 208, 255);
    }
}

.second-division {
    margin-bottom: 20px;
    button {
        color: red;
        border: 1px solid red;
    }
          
}