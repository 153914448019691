.adminProductVariationItem {
    display: flex;
    flex-direction: column;

    &>span {
        font-weight: 800;
    }
    
    &>div {
        display: grid;
        grid-template-columns: 5fr 5fr;
        column-gap: .5em;
    }
}