.gridTableImgDiv {
	width: 100%;
	height: fit-content;

	& > .gridTableImg {
		width: 100%;
		height: auto;
		object-fit: cover;
	}
}

.adminActionDiv {
	& > .adminActionContDiv {
		.MuiDataGrid-cell:focus,
		.MuiDataGrid-columnHeader:focus {
			outline: none !important;
		}

		& > .dataGrid {
			height: 631px;
			width: 100%;

			.MuiDataGrid-row:last-of-type {
				border-bottom: 1px solid rgba(224, 224, 224, 1);
			}
		}
	}
}

.adminActionHeading {
    grid-template-columns: 7fr 1.5fr 1.5fr !important;
}

.actionTab {
	text-decoration: underline;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.infoLabel {
	font-family: "OpenSansRegular";
	font-size: 13px;
	color: #ec5252;
}
