.qrCodeScannerDiv {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    z-index: 1210;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto !important;
    overflow: hidden;
    opacity: 0;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    animation: fadeIn 1s forwards cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-delay: .5s;
    overflow: hidden !important;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    &>.qrCodeScannerContDiv {
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        &>.closeIcon {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 10;
            cursor: pointer;
            border-radius: 5px;
            background: #fff;
            border: 1px solid #e2e2e2;
        }

        &>.qrCodeScannerScanning {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            background: rgba(0,0,0,.5);
            width: 100vw;
            height: 100vh;
            display: grid;
            place-items: center;
        }

        &>video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &>.qrCodeScannerActionDiv {
            position: absolute;
            z-index: 9;
            width: 80%;
            bottom: 20px;
            display: flex;
            flex-direction: column;

            &>.iconTransparentButton {
                background: #fff;
            }
    
            &>.qrCodeScannerMessage {
                background: #fff;
                border-radius: 5px;
                margin-bottom: 1em;
                padding: 20px;
                font-family: 'PoppinsRegular';
            }
        }
    }
}