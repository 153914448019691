.imageUploadMainDiv {
    position: relative;
    .imageUploadDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 300px;
        border: 1px solid #E2E2E2;
    
        &>.imageUploadImg {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    
        &>.imageUploadInput {
            display: none;
        }
    
        &:hover, &:-moz-drag-over {
            border: 1px dotted red;
        }
    }

    &>.imageUploadRemove {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        z-index: 2;
        border-radius: 1px;
        background: #fff;
    }
}

.imgRestrictionDiv {
    display: flex;
    align-items: center;
    opacity: .8;
    font-size: 15px;
    color: #EC5252;
}

.imgRestrictionBorder {
    border: 1px solid #EC5252 !important;
}