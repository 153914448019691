.userRegisterDiv
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    &>.userRegisterContDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &>.userRegisterTitle {
            text-align: center;
            font-size: 1.5em;
            width: 100vw;
            font-family: 'OpenSansBold';
            color: #A86C6B;
            margin-bottom: 1em;
        }

        &>.userRegisterForm {
            width: 25vw;
            border-radius: 10px;  
            background: #F7F7F7;
            padding: 5em;
            text-align: center;
            position: relative;

            &>.userRegisterSubTitle {
                width: 100%;
                position: absolute;
                top: 5%;
                left: 0;
                letter-spacing: .05em;
                font-family: 'OpenSansBold';
                text-transform: uppercase;
                color: #A86C6B;
                font-size: 1.5em;
            }

            @media only screen and (max-width: 768px) {
                width: 45vw;
            }

            &>.errorMsg {
                font-size: 13px;
                color: red;
                font-family: 'OpenSansRegular';
            }

            &>.haveAccount {
                width: 100%;
                position: absolute;
                bottom: 5%;
                left: 0;
                display: flex;
                margin-top: 1em;
                font-family: 'OpenSansRegular';
                justify-content: center;

                &>a {
                    cursor: pointer;
                    color: inherit;
                }
            }
        }
    }
}