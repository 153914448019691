.myAccountDropdownModalDiv {
    position: absolute;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &>.myAccountDropdownModalMain {
        cursor: pointer;
        transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        z-index: 1204;
        fill: #A86C6B;
        stroke: #fff;
        opacity: 1;
        position: relative;
    }

    &>.active {
        opacity: .8;
    }

    .slideIn {
        opacity: 1 !important;
        transition: 1s ease !important;
        top: 50px !important;
        @media only screen and (max-width: 1000px) {
            left: 0px !important;
            top: 0px !important;
        }
    }

    &>.myAccountDropdownModalCont {
        width: fit-content;
        height: fit-content;
        background: #fff;
        position: fixed;
        opacity: 0;
        top: -150px;
        right: 24px;
        color: #000;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        padding: 20px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-family: 'OpenSansRegular';
        user-select: none;
        z-index: 1203;
        transition: 1s ease;

        @media only screen and (max-width: 1000px) {
            width: 100%;
            height: 100%;
            left: 1000px;
            top: 0;
            padding: 0;
            border-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &>.myAccountDropdownModalContUsername {
            font-family: 'OpenSansSemiBold';
            font-size: 18px;
        }

        &>.divider {
            background: #000;
            height: 1px;
            margin: .5em 0em;
            opacity: .3;
        }

        &>.logout {
            background: #A86C6B;
            border-radius: 5px;
            color: #F7F7F7;
            padding: 1em;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: .1em;
            cursor: pointer;
            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            opacity: 1;

            &:hover {
                opacity: .8;
            }

            @media only screen and (max-width: 1000px) {
                position: absolute;
                bottom: 1em;
                width: 85vw;
                margin-top: 20em;
            }
        }

        &>.navigationBox {  
            width: 100%;
            font-size: 15px;

            @media only screen and (max-width: 1000px) {
                width: 90%;
            }
                
            & > .navigationBoxLink {
                width: fit-content;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;
                position: relative;
                cursor: pointer;
                margin: .5em 0em;
            }
        
            ::after {
                content: "";
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: #000;
                opacity: .5;
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;
            }
        
            :hover::after {
                transform: scaleX(1);
                transform-origin: bottom left;
                opacity: .5;
            }
        }
    }
}

.activeIcon {
    background: #fff;
    padding: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &::before {
        content: '';
        background: transparent;
        width: 10px;
        height: 10px;
        border-bottom-right-radius: 5px;
        position: absolute;
        top: 22px;
        left: -10px;
        box-shadow: 5px 5px 5px #fff;

        @media only screen and (max-width: 1000px) {
            box-shadow: none;
        }
    }

    @media only screen and (max-width: 1000px) {
        background: transparent;
    }
}