.formBottomButton {
    background: #A86C6B;
    font-family: 'OpenSansSemiBold';
    font-size: 1.1em;
    text-transform: uppercase;
    letter-spacing: .2em;
    user-select: none;
    cursor: pointer;
    padding: 1em 8em;
    color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    opacity: 1;
    position: relative;

    &:hover {
        opacity: .9;
    }
}

.formBottomButtonDisabled {
    cursor: not-allowed;
    opacity: .5;
}

.formBottomButtonSubmitting {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}