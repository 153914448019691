.bookingSlotDiv {
    height: 100%;
    width: 100%;
    background: transparent;
    color: #A86C6B;
    font-family: 'OpenSansRegular';
    padding: 1em;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-radius: 5px;

    &>.incrementId {
        font-weight: 800;
        font-size: 1.5em;
    }

    &>span {
        position: relative;
        height: 100%;
        &>.bookingSlotCont {
            position: absolute;
            top: 0;
            left: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            transform: rotate(0deg);
            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

            @media only screen and (max-width: 1000px) {
                position: absolute;
                top: auto !important;
                bottom: -5px;
                left: 15px;
                transform: rotate(-90deg);
                transform-origin: left bottom;
            }
        }
    }
}

.bookingSlotDivDisabled {
    cursor: not-allowed !important;
    color: #000 !important;
    background: #9b9b9b !important;
    opacity: .5 !important;
}

.bookingSlotDivUnpaid {
    color: #fff !important;
    background: #A86C6B !important;
}

.bookingSlotDivNew {
    opacity: .5 !important;
}