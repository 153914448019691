.faqContentContainer {
    height: auto;
    max-height: fit-content;
    margin-top: var(--header-height);
    padding: 40px !important;
    margin-bottom: 40px !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    justify-content: center !important;
    align-items: center !important;
}

.faqContentDiv {
    display: grid;
    grid-template-columns: 1fr 3fr;
    height: 100%;
    width: 80%;

    @media only screen and (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }

    &>.faqHeader {
        border-right: 1px dashed #b2a08b;

        @media only screen and (max-width: 1000px) {
            border-right: 0;
        }

        &>p {
            font-size: 40px;
            font-family: "PoppinsSemiBold";
            color: #5d3b23;

            @media only screen and (max-width: 1000px) {
                font-size: 24px;

            }
        }
    }

    &>.faqAccordionDiv {
        width: 80%;
        margin-left: 20%;
        height: 100%;

        @media only screen and (max-width: 1000px) {
           margin: 0;
        }

        &>.accordionOuter {
            background-color: #f4eee9;
            margin-bottom: 20px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            .accordionTitle {
                font-family: "PoppinsSemiBold";
                font-size: 20px;
                color: #5d3b23;

                @media only screen and (max-width: 1000px) {
                    font-size: 16px;
                }
            }
        }
    }

    .faqDetailsContent {
        color: #5d3b23;
        margin-bottom: 20px;

        &>.accordionQuestion {
            font-family: "PoppinsSemiBold";
            font-size: 16px;    

            @media only screen and (max-width: 1000px) {
                font-size: 12px;
            }
        }

        &>.accordionAnswer {
            font-family: "PoppinsRegular";
            font-size: 16px; 
            
            @media only screen and (max-width: 1000px) {
                font-size: 12px;
            }
        }

        
    }
}