.errorContainer {
    width: 100%;
    height: auto;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &>.errorAdminContent {
        margin: 0 auto !important;
        width: 100% !important;
    }

    &>.errorContent {
        height: 100vh;
        width: 80%;
        margin: 130px auto 0;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
        background-color: #f4eee9;
        padding: 20px;

        &>.errorContentHeader {
            font-family: "SourceSerifSemiBold";
            font-size: 2rem;
            text-transform: uppercase;
            color: #5d3b23;
        }
         
        &>.errorImage {
            height: 450px;
            &>img {
                height: 100%;
                width: 100%;
            }
            margin-bottom: 20px;
        }

        &>.errorContentBottom {
            font-family: "SourceSerifSemiBold";
            font-size: 2rem;
            text-transform: uppercase;
            color: #5d3b23;
        }

        &>.errorButton {
            height: 100px;
            &>a {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            
        }
    }
}