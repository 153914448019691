.adminActionDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: 'OpenSansRegular';
    position: relative;
    user-select: none;

    &>.adminActionContDiv {
        width: 80%;
        padding: 20px;
        .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus {
            outline: none !important;
        }
    }

    &>.adminActionHeading {
        position: sticky !important;
        top: var(--admin-panel-header-height) !important;
        width: 100% !important;
        padding: 20px;
        z-index: 1202 !important;
        color: #434343;
        background: #F7F7F7;
        box-shadow: 5px 5px 5px rgba(0,0,0,.1);
        font-family: 'OpenSansBold';
        display: grid;
        grid-template-columns: 9fr 1fr;

        &>span {
            font-size: 35px;
        }
    }

    &>.adminActionTable {
        margin: 5em 0em;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &>tbody>tr {
            &>.adminActionHeader {
                text-align: right;
                padding-right: 1em;
            }
        }
    }
}