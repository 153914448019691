.textAreaDiv {
    margin-bottom: 20px;
}

.textAreaCustom {
    width: 100%;
    font-size: 15px;
    font-family: 'OpenSansRegular';
    resize: vertical;
}

.emptyDescription {
    border-color: #d32f2f !important;
}