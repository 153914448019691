.treatmentDropdownImage {
    width: 2em;
    height: 2em;
    object-fit: cover;
    border-radius: 5px;
    margin-right: .5em;
}

#treatment-dropdown-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}