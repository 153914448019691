.heroBannerDiv {
	height: 100vh;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);


	.slick-slide {
		display: flex !important;
		justify-content: center;
		align-items: center;
		height: 100vh !important;
		object-fit: cover;

		& > div {
			& > .heroBannerDiv {
				position: relative;
				& > .heroBannerImg {
					object-fit: cover;
					width: 100vw;
				}

				& > .heroContentSection {
					position: absolute;
					top: 0;
					height: 100%;
					display: grid;
					place-content: center;

					&>.heroContentDiv {
						margin-left: 120px;
						width: 50%;
						height: fit-content;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;

						@media only screen and (max-width: 1000px) {
							margin-left: 0;
						}

						&>.heroContentTitle {
							font-family: "CinzelDecorativeRegular";
							font-size: 5em;
							font-weight: 900;
							text-transform: uppercase;
							color: #fff;

							@media only screen and (max-width: 450px) {
								font-size: 1.5em !important;
							}
						}

						&>p {
							word-break: break-all;
							font-family: "OpenSansRegular";
							text-align: left;
							color: #fff;
						}
					}
				}

				@media only screen and (max-width: 1000px) {
					width: 100vw;
					height: 100vh;

					&>.heroBannerImg {
						filter: brightness(50%);
						object-fit: cover;
						width: 100%;
						height: 100%;
					}

					&>.heroContentSection {
						position: absolute;
						top: 0;
						width: 100%;
						height: 100%;
						display: grid;
						place-content: center;

						&>.heroContentDiv {
							width: 80vw;
							height: fit-content;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							&>.heroContentTitle {
								font-size: 3em;
								display: flex;
								flex-direction: column;
							}

							&>p {
								font-family: "OpenSansRegular";
								text-align: left;
								color: #d6d6d6;
								font-size: 0.8em;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}

.slick-dots {
	max-width: 50px;
	display: flex !important;
	flex-direction: column;
	top: 40% !important;
	left: 20px;
	transition: 0.3s;

	li {
		margin-bottom: 20px !important;
		color: white;
	}

	@media only screen and (max-width: 1000px) {
		left: 2px;

		li {
			margin-bottom: 10px !important;
			color: white;
		}
	}
}

.sliderNavigation {
	z-index: 888;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.navButton {
		width: 12px;
		height: 12px;
		border: 2px solid #fff;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: 0 0 2px rgb(255, 254, 254);
		transition: all 0.2s ease-out;

		@media only screen and (max-width: 1000px) {
			width: 8px;
			height: 8px;
		}
	}

	.navButton:hover {
		transform: scale(1.2);
		background: #fff;
	}
}

.slick-active {
	.navButton {
		transition: all 1.2s ease-in;
		background: #fff;
	}
}
