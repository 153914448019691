.aboutUsContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 65%;
    margin: 100px auto 50px;

    @media only screen and (max-width: 1000px) {
        flex-direction: column;
    }

    &>.aboutUsImage {
        width: 50%;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
        }

        @media only screen and (max-width: 1000px) {
            height: auto;
            width: 100vw;
            img {
                border-radius: 0px;
            }
        }
    }

    .aboutUsDesc {
        margin: 0 5rem;
        width: 50%;
        color: #5d3b23;

        @media only screen and (max-width: 1000px) {
            margin: 20px 3rem 0 3rem;
            width: 80vw;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        div:first-of-type {
            text-align: center;
        }

        .aboutUsTitle {
            font-family: "PoppinsBold";
            font-size: 3rem;
            font-weight: 900;
            letter-spacing: 4px;
            margin-bottom: 20px;
        }

        &>.motto {
            font-family: "SourceSerifBold";
            font-style: italic;
            font-size: 3em;
            margin: 10px 0 10px 0;
            white-space: nowrap;
            text-transform: capitalize;

            @media only screen and (max-width: 1000px) {
                font-size: 2em;
            }
        }

        .aboutUsTitleDesc {
            font-size: 18px;
            line-height: 20px;
            font-family: "PoppinsRegular";
            margin-bottom: 40px;

            .descTitle {
                font-weight: bold;
                letter-spacing: 1.5px;
                font-size: 20px;

                @media only screen and (max-width: 1000px) {
                    font-size: 15px;
                }
            }

            &>.descBody {
                font-family: 'PoppinsRegular';
                font-size: 20px;

                @media only screen and (max-width: 1000px) {
                    font-size: 15px;
                }
            }
        }
        
    }
}

/* About Us Explore Button */
.aboutUsButton {
    display: inline-block;

    .exploreButton {
        text-decoration: none;
        font-family: "PoppinsRegular";
    }    

    .buttonText {
        font-family: "PoppinsSemiBold";
    }

    .aboutUsHiddenText {
        display: inline-block;
        width: 0px;
        opacity: 0;
        color: #fff;
        transition: .5s;
        transform: translateX(20px);
        white-space: nowrap;
        pointer-events: none;
        font-family: "PoppinsRegular";
    }
}

/* Hover effect */
.aboutUsButton:hover {

    button {
        background: #AC8435;
        border: 1px solid #AC8435;
    }

    .aboutUsHiddenText {
        opacity: 1;
        color: #000000;
        transform: translateX(40px);
    }
}

/* A line to seperate div */
.break-line {
    max-width: 65%;
    margin: 0 auto;
    border-top: 2px solid #ccc;
}