.actionActionBookingTable {
    border-spacing: 0;
    border-collapse: separate;
    &>tbody {
        &>tr {
            border: 1px solid rgba(0, 0, 0, 0.3);
            &>td {
                border: 1px solid rgba(0, 0, 0, 0.3);
                &>.actionActionBookingTreatment {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    &>img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
        &>tr:last-child td:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
            
        &>tr:last-child td:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

.rescheduleBookingDiv {
    width: 100%;
    &>.bookingDiv {
        width: 94vw !important;
        padding-top: var(--admin-panel-header-height);

        .fc .fc-toolbar.fc-header-toolbar {
            background: #fff;
            position: fixed;
            top: 0;
            padding-top: calc(var(--admin-panel-header-height) + 1em);
            padding-bottom: 1em;
            width: 94vw !important;
            z-index: 10;
        
            @media only screen and (max-width: 1000px) {
                padding-top: var(--admin-panel-header-height);
                margin-bottom: auto !important;
                width: 90vw !important;
            }
        }
    }
}

.bookingCallDiv {
    transform: scale(.8);
}

.adminActionBookingHeading {
    grid-template-columns: 7fr 1.5fr 1.5fr !important;
}