.myAccountTitle {
    font-size: 25px;
    font-family: 'PoppinsBold';
    text-align: left;
    width: 100%;
    color: #6F5B4A;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    position: sticky;

    @media only screen and (max-width: 1000px) {
        display: none;
    }
}