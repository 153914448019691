.userForgotPasswordDiv
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    &>.userForgotPasswordContDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &>.userForgotPasswordForm {
            width: 25vw;
            border-radius: 10px;  
            background: #F7F7F7;
            padding: 5em;
            text-align: center;
            position: relative;

            &>.userForgotPasswordTitle {
                width: 100%;
                position: absolute;
                top: 5%;
                left: 0;
                letter-spacing: .05em;
                font-family: 'PoppinsBold';
                text-transform: uppercase;
                color: #A86C6B;
                font-size: 1.5em;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &>.userForgotPasswordSubTitle {
                    width: 100%;
                    letter-spacing: .05em;
                    font-family: 'PoppinsBold';
                    text-transform: uppercase;
                    color: #A86C6B;
                    font-size: .5em;
                }
            }
            
            &>.loginNow {
                font-family: 'PoppinsRegular';
                color: #000;
            }
            
            @media only screen and (max-width: 768px) {
                width: 45vw;
            }

            &>.errorMsg {
                width: 100%;
                position: absolute;
                bottom: 5%;
                left: 0;
                display: flex;
                margin-top: 1em;
                font-family: 'PoppinsRegular';
                justify-content: center;
                font-size: 13px;
                color: red;
            }
        }
    }
}
