/** START title */
@font-face {
    font-family: "SourceSerifRegular";
    src: url(./SourceSerifPro-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "SourceSerifSemiBold";
    src: url(./SourceSerifPro-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: "SourceSerifBold";
    src: url(./SourceSerifPro-Bold.ttf) format('truetype');
}
/** END title */

/** START body */
@font-face {
    font-family: 'OpenSansRegular';
    src: url(./OpenSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSansMedium';
    src: url(./OpenSans-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSansMediumItalic';
    src: url(./OpenSans-MediumItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSansLight';
    src: url(./OpenSans-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSansLightItalic';
    src: url(./OpenSans-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSansBold';
    src: url(./OpenSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: url(./OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSansExtraBold';
    src: url(./OpenSans-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: "Javacom";
    src: url(./Javacom.otf) format('opentype');
}

@font-face {
    font-family: "PoppinsLight";
    src: url(./Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: "PoppinsRegular";
    src: url(./Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: "PoppinsBold";
    src: url(./Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url(./Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: "PoppinsSemiBoldItalic";
    src: url(./Poppins-SemiBoldItalic.ttf) format('truetype');
}
/** END body */

