.maintenanceDiv
{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #6F5B4A;
    position: relative;
    font-family: 'PoppinsBold';

    &>.maintenanceTitle 
    {
        font-weight: 700;
        font-size: 30px;
    }

    &>.maintenanceSubtitle {
        font-family: 'PoppinsRegular';
    }

    &>.maintenanceIcon
    {
        position: absolute;
        font-size: 15em;
        opacity: .1;
    }
}