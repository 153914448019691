.adminConfigDiv {
    // width: fit-content;
    display: grid;
    place-items: center;
    font-family: 'OpenSansRegular';

    &>.adminConfigSubmit {
        width: 100%;
        top: 0;
        height: fit-content;
        position: sticky;
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr 9fr;
    }
}

.tdHeader {
    text-align: right;
    padding-right: 1em;
    width: 25%;
}

.infoTd {
    font-family: 'OpenSansLight';
    font-size: 13px;
}

.configSectionTitle {
    display: flex;
    width: 100%;
    align-items: center;
}

.configLogo {
    width: 2em;
    height: 2em;
    display: grid;
    place-items: center;
    margin-right: .5em;
    background: #fff;
    box-shadow: 1px 1px 1px rgba(0,0,0,.1);
    border-radius: 5px;
    padding: .1em;
}