.myAccountPersonal {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 9fr;
    user-select: none;
    position: relative;
    padding: 50px;

    &>.myAccountPersonalTable {
        width: 100%;
        height: fit-content;
        position: relative;
        font-family: 'OpenSansRegular';
        padding: 10px 50px;

        &>tbody {
            &>.myAccountPersonalTableRow {
                &>.myAccountPersonalTableRowCont {
                    padding-left: 1em;
                    &>.errorMsg {
                        font-family: 'OpenSansRegular';
                        color: red;
                        font-size: 13px;

                        @media only screen and (max-width: 1000px) {
                            &:before {
                                content: '\a';
                                white-space: pre;
                                font-size: 12px;
                            }
                        }
                    }

                    &>.myAccountPersonalSwitch {
                        cursor: pointer;
                    }

                    &>.myAccountPersonalSwitchDisabled {
                        cursor: not-allowed;
                    }
                }
                &>.myAccountPersonalTableRowTitle {
                    text-align: right;
                }
            }
            &>.myAccountPersonalTableInfo {
                &>.myAccountPersonalTableRowCont {
                    padding-left: 1em;
                }
                font-family: 'OpenSansLight';
                font-size: 13px;
            }
        }
    }
}