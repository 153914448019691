.productListingContainer {
    width: 100vw;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &>.productListingDiv {
        // height: 100vh;
        margin: 120px auto 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 20px;
        position: relative;

        @media screen and (max-width: 1000px) {
            width: 100%;
            margin-top: var(--header-height);
        }

        &>h2 {
            font-family: "SourceSerifSemiBold";
            letter-spacing: 5px;
            color: #5d3b23;  
            -webkit-text-stroke: 1.5px #5d3b23;

            @media screen and (max-width: 1000px) {
                font-size: 40px;
                font-family: "SourceSerifRegular";
            }
        }

        &>.productListingGrid {
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            place-items: center;
            gap: 1em;

            @media screen and (max-width: 1000px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            &>.productListingItemDiv {
                cursor: pointer;
                text-decoration: none;

                @media screen and (max-width: 1000px) {
                    margin-left: 0;
                    margin-bottom: 0;
                }

                &>.productContent {
                    padding: 10px;
                    background: #f5f5f5;
                    position: relative;
                    border-radius: 10px;
                    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3); 

                    &>.productImg {
                        background: #fff;
                        padding: 50px;
                        width: 200px;
                        height: 200px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-size: 300px 300px;
                        background-repeat: no-repeat;

                        @media screen and (max-width: 1260px) {
                            height: 100px;
                            width: 100px;
                            background-repeat: round;
                        }

                        @media screen and (max-width: 1000px) {
                            width: 50px;
                            height: 50px;
                            background-size: 150px 150px;
                        }
                    }
                }

                &>.productTitle {
                    margin-top: 5px;

                    &>h6 {
                        font-family: "PoppinsRegular";
                        color: #5d3b23;
                        font-size: 1em;

                        @media screen and (max-width: 1260px) {
                            font-size: 14px;
                        }

                        @media screen and (max-width: 1000px) {
                            width: 170px;
                            word-wrap: break-word;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}