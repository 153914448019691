.myAccountDiv {
    width: 100vw;
    height: fit-content;
    display: grid;
    grid-template-columns: 3fr 7fr;

    @media only screen and (max-width: 1000px) {
        display: flex;
        flex-direction: column;
    }

    &>.myAccountSideMenuDiv {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: auto;

        @media only screen and (max-width: 1000px) {
            width: 100vw;
            height: fit-content;
            position: relative;
            z-index: 2;
        }

        &>.myAccountSideMenu {
            display: flex;
            flex-direction: column;
            width: fit-content;
            border: 2px solid #f4f1ec;
            padding: 20px;
            border-radius: 5px;
            user-select: none;
            position: fixed;
            top: 20%;
            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

            @media only screen and (max-width: 1000px) {
                width: 100vw;
                height: fit-content !important;
                display: flex;
                border-radius: 0px;
                position: fixed;
                top: 0;
                padding-top: var(--header-height);
                overflow: hidden;
                background: #f4f4f4;
            }

            &>.myAccountSideMenuCaret {
                display: none;
                cursor: pointer;
                color: #A86C6B;
                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                margin-left: 10px;
                position: absolute;
                bottom: 5px;
                left: 20px;
                z-index: 10;

                @media only screen and (max-width: 1000px) {
                    display: flex;
                }
            }

            &>.myAccountSideMenuCaretActive {
                transform: rotate(-180deg);
                transform-origin: center center;
                position: relative !important;
                width: fit-content !important;
                margin-bottom: 20px !important;
            }

            &>.myAccountSideMenuLevel0 {
                font-family: 'OpenSansSemiBold';
                width: fit-content;
                cursor: default;
                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

                @media only screen and (max-width: 1000px) {
                    transform: scale(0);
                    display: none;
                }
            }

            &>.myAccountSideMenuLevel1 {
                font-family: 'OpenSansRegular';
                width: fit-content;
                margin-left: 1.5em;
                font-size: 14px;
                color: inherit;
                text-decoration: none;
                cursor: pointer;
                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

                @media only screen and (max-width: 1000px) {
                    transform: scale(0);
                    display: none;
                }

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }

            &>.myAccountSideMenuLevelActive {
                display: block;
                padding: 10px;
                transform: scale(1);               
                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                display: flex;
                justify-content: flex-start !important;
                align-items: flex-start !important;
                width: 100%;
            }

            &>.myAccountSideMenuLevelDActive {
                margin-left: 1.5em !important;
            }

            &>.myAccountSideMenuActive {
                font-family: 'OpenSansBold';
                color: #A86C6B;
                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

                @media only screen and (max-width: 1000px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-left: 0;
                    transform: scale(1);
                    text-decoration: none !important;
                    cursor: default !important;
                }
            }
        }
    }

    &>.myAccountPageDiv {
        background: #F4F1EC;
        margin-top: var(--header-height);
        height: fit-content;
        width: 100%;

        @media only screen and (max-width: 1000px) {
            margin-top: calc(var(--header-height));
        }
    }
}