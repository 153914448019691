.actionButton {
    cursor: pointer;
    background: #C5C1BE;
    padding: 1em 0em;
    color: #000;
    white-space: nowrap;
    user-select: none;
    font-family: 'OpenSansBold';
    text-align: center;
    position: relative;
    margin: .1em;
    text-transform: uppercase;

    &:hover {
        opacity: .8;
    }
}

.actionButtonDisabled {
    opacity: .5;
    cursor: not-allowed;
}

.actionButtonSubmitting {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.actionButtonOffline {
    display: flex;
    justify-content: center;
    align-items: center;
}