.singleTestDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &>.singleTestContent {
        &>span {
            margin: 0 !important;
            font-family: "PoppinsRegular";
            text-transform: uppercase;

            @media only screen and (max-width: 1000px) {
                font-size: 12px;
            }
        }
    
        &>img {
            width: 100%;
            object-fit: contain;
            -webkit-user-drag: none;
        }
    }

    &>.singleTestBefore {
        &>img {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }

    &>.singleTestAfter {
        &>span {
            font-family: 'PoppinsSemiBold';
        }

        &>img {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}