.headerAppbar {
    background: transparent !important;
    transition: .3s ease-in-out !important;

    &:focus, &:active, &:hover {
        background: #fff !important;
        box-shadow: 5px 5px 5px rgba(0,0,0,.1) !important;

        .leftHeaderLink, .rightHeaderLink {
            color: #A86C6B !important;
        }
    }
    user-select: none;
}

.headerOnScroll {
    background: #fff !important;
    opacity: 1 !important;
    box-shadow: 5px 5px 5px rgba(0,0,0,.1) !important;

    &:focus, &:active, &:hover {
        background: #fff !important;
    }
}

.headerOnPage {
    background: #fff !important;
    opacity: 1 !important;
    box-shadow: 5px 5px 5px rgba(0,0,0,.1) !important;

    &:focus, &:active, &:hover {
        background: #fff !important;
    }
    
    .leftHeaderLink, .rightHeaderLink {
        color: #A86C6B !important;
    }
}

.headerOnScrollLink {
    color: #A86C6B !important;
    opacity: 1 !important;
    transition: .3s ease-in-out !important;

    &:focus, &:active, &:hover {
        opacity: .7 !important;
    }
}

.headerLogo {
    background-image: url("../../assets/logo/beauvie_logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
}

.navbar-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    position: relative !important;
    // box-shadow: 0 25px 21px -10px rgba(0,0,0,.1) !important;
}

.leftHeaderLink, .rightHeaderLink {
    padding: 20px;
    text-decoration: none;
    font-family: "PoppinsSemiBold";
    color: white;
    white-space: nowrap;
    opacity: 1;
    transition: .3s ease-in-out;

    &:hover {
        color: #9d5c58;
        opacity: .8;
    }
}

.navbar-links {
    a {
        width: fit-content;
    }
}

.header-title {
    letter-spacing: 0.1em !important;
    white-space: nowrap !important;
    width: 3em !important;
    height: 3em !important;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #A86C6B;
    transition: 0.3s;
    box-shadow: 1px 1px 1px rgba(0,0,0,.1);
}

@media only screen and (max-width: 1000px) {

    .hamburger {
        display: block;
        z-index: 1204;
    }

    .hamburger.active .bar:nth-child(2){
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1){
        transform: translateY(8px) rotate(45deg);
        background-color: #A86C6B;
    }

    .hamburger.active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
        background-color: #A86C6B;
    }

    .loginButton {
        align-items: center !important;

        .personIcon {
            font-size: 25px;
        }
    }

    .navbar-mobile-container {
        justify-content: space-between !important;

        .navbar-links {
            display: flex;
            background-color: #FFFBF4;
            flex-direction: column !important;
        }
    }

    .MuiGrid-item {
        padding: 0 !important;
    }

    .header-title {
        position: fixed;
        opacity: 1 !important;
        top: 0 !important;
        padding-top: .1em;
        width: 2em !important;
        height: 2em !important;
        color: #A86C6B !important;
    }

    .leftHeaderLink, .rightHeaderLink {
        padding: 20px;
        text-decoration: none;
        color: black;
        background-color: transparent !important;
        white-space: nowrap;
        opacity: 0.5;
        transition: .3s;

        &:hover, .nav-link:focus
        {
            opacity: 1;
        }
    }

    .navbar-links {
        position: fixed;
        left: -1000px !important;
        top: 0;
        gap: 0;
        flex-direction: column;
        width: 100vw !important;
        max-width: 100% !important;
        height: 100vh;
        text-align: center;
        transition: 1s ease;  
        z-index: -5 !important;
        opacity: 0;
        z-index: -10 !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }

    .navbar-links.active {
        opacity: 1;
        left: 0 !important;
        z-index: 1203 !important;
    }

    .headerAppbar {
        &:focus, &:active, &:hover {
            background: transparent !important;
            box-shadow: none !important;
        }
    }

    .headerOnScroll {
        background: transparent !important;
        box-shadow: none !important;
    
        &:focus, &:active, &:hover {
            background: transparent !important;
        }
    }
}
