.bookingDiv {
    width: 100vw;
    height: fit-content;
    position: relative;
    margin-top: var(--header-height);

    &>.bookingSnackbar {
        margin-bottom: var(--treatment-selection-height);
    }
}

.fc .fc-toolbar.fc-header-toolbar {
    background: #fff;
    position: fixed;
    top: 0;
    padding-top: calc(var(--header-height) + 1em);
    padding-bottom: 1em;
    width: 100vw;
    z-index: 10;

    @media only screen and (max-width: 1000px) {
        padding-top: var(--header-height);
        margin-bottom: auto !important;
    }
}

.fc .fc-view-harness {
    margin-top: var(--header-height);
}

.fc .fc-button-primary {
    background: #A86C6B !important;
    border-color: #fff !important;
    font-family: 'OpenSansRegular' !important;
}

.MuiPickersPopper-root {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.3);
    display: grid;
    place-items: center;
}

.fc-timegrid-slot {
    height: calc(100vh/4) !important;
}

.fc-col-header-cell {
    height: 2em;

    &>div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }
}

.fc-toolbar-title {
    cursor: pointer;
    user-select: none;
    font-weight: normal;
    font-family: 'OpenSansRegular';
    @media only screen and (max-width: 1000px) {
        font-size: 20px !important;
    }
}

.fc-v-event {
    background: #fff !important;
    border-color: #d6d6d6 !important;
}

.fc-direction-ltr .fc-timegrid-col-events {
    margin: 0 0 0 25% !important;

    @media only screen and (max-width: 1000px) {
        margin: 0 0 0 25% !important;
    }
}

// .fc-timegrid-slot-lane {
//     background-color: red !important;
//     position: relative !important;
// }

// .fc-timegrid-slot-lane::before {
//     content: 'Fully Booked' !important;
//     position: absolute !important;
//     left: -10px !important;
//     top: 75px !important;
//     transform: rotate(270deg);
// }

.bookingInfoTable {
    font-family: 'OpenSansRegular';

    &>tbody>tr>.bookingInfoHeader {
        font-family: 'OpenSansSemiBold';
        text-align: left;
    }

    &>tbody>tr>.bookingInfoValue {
        margin-left: 1em;
    }

    &>tbody>tr>.bookingInfoValue::before {
        content: ":";
    }
}

.bookingInfoContactCustomerDiv {
    display: grid;
    grid-template-columns: 5fr 5fr;
}

.bookingPlaceholder {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 25% auto;

    &>.clickableDiv {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #b4b4b4;
        font-family: 'OpenSansRegular';

        &>span {
            font-size: 13px;
        }
    }
}

.bookingWalkInDiv {
    padding-top: 1em;
    font-family: 'OpenSansRegular';

    &>div{
        &>span {
            font-family: 'OpenSansBold';
        }
    }
    &>span {
        font-size: 13px;
        font-family: 'OpenSansLight';
    }
}