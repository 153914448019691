.myAccountBookingItemDiv {
    width: 90%;
    height: fit-content;
    background: #fff;
    color: #6F5B4A;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    margin: .5em;
    transition: .3s cubic-bezier(0.075, 0.82, 0.165, 1);

    &>.myAccountBookingItemHeader {
        color: #6F5B4A;
        background: #EEE5DC;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1em;

        &>.myAccountBookingItemId {
            margin-left: .1em;
            font-weight: 700;
            font-size: 15px;
            font-family: 'PoppinsBold';
            display: flex;

            &>div {
                display: flex;
            }

            @media only screen and (max-width: 1000px) {
                font-size: 12px;

                &>div {
                    display: none;
                }
            }

            &>span {
                font-family: 'PoppinsLight';
            }
        }

        &>.myAccountBookingItemStatus {
            text-align: end;
            text-transform: uppercase;
            font-family: 'PoppinsBold';
            margin-right: .1em;

            @media only screen and (max-width: 1000px) {
                font-size: 12px;
            }
        }
    }
    
    &>.myAccountBookingItemGridDiv {
        display: grid;
        grid-template-columns: 1fr 9fr;
        align-items: center;

        &>.myAccountBookingItemCont {
            padding: 1em;
            display: flex;
            flex-direction: column;
            font-family: 'PoppinsLight';
            margin-left: .5em;
            color: #6F5B4A;
            text-align: left;
    
            @media only screen and (max-width: 1000px) {
                font-size: 11px;
            }
    
            &>.myAccountBookingItemTitle {
                text-decoration: underline;
                text-transform: uppercase;
            }

            &>table>tbody>tr {
                .myAccountBookingItemContHint {
                    font-size: 12px;
                    color: red;

                    @media only screen and (max-width: 1000px) {
                        font-size: 9px;
                    }
                }
                &>.myAccountBookingItemActionData, &>td {

                    &>.bookingItemRepaymentTimerDiv {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        &>span {
                            font-family: 'PoppinsRegular';
                            font-size: 2em;
                            display: flex;
                            justify-content: center;
                        }

                        &>.bookingItemRepaymentInfoDiv {
                            display: flex;
                            flex-direction: row;
                            text-align: center;
    
                            &>svg {
                                width: 2.5em;
                                margin-right: .5em;
                            }
    
                            &>div {
                                display: flex;
                                flex-direction: column;
                                text-align: left;
    
                                &>.title {
                                    font-family: 'PoppinsBold';
                                    text-transform: uppercase;
                                }
    
                                &>.hint {
                                    font-family: 'PoppinsLight';
                                    font-size: .8em;
                                    line-height: 15px;
                                }
                            }
                        }
                    }

                    &>.bookingItemRepaymentActionDiv {
                        display: grid;
                        grid-template-columns: 8fr 1fr 8fr;
                        align-items: center;
                        text-align: center;
                    }

                    &>.bookingItemRepaymentHintDiv {
                        display: grid;
                        place-items: center;
                    }
                }
            }
        }
    
        &>.myAccountBookingItemTreatmentImgDiv {
            padding: 1em;
            height: fit-content;
            position: relative;

            @media only screen and (max-width: 1000px) {
                transform: scale(.8);
                padding: 0;
            }
    
            &>.myAccountBookingItemTreatmentImg {
                object-fit: cover;
                width: 10em;
                height: 10em;
                border-radius: 5px;
                border: 1px solid #E2E2E2;

                @media only screen and (max-width: 1000px) {
                    width: 5em;
                    height: 5em;
                }
            }

            &>.myAccountBookingItemTreatmentImgSub {
                position: absolute;
                bottom: 15px;
                right: 15px;
                object-fit: cover;
                width: 30%;
                height: 30%;
                border-bottom-right-radius: 5px;
                border: 1px solid #E2E2E2;
            }
        }
    }
}

.myAccountBookingItemContAccordion {
    padding: 1em;
    display: flex;
    flex-direction: column;
    font-family: 'PoppinsLight';
    color: #6F5B4A;
    text-align: left;
    border-radius: 5px;

    @media only screen and (max-width: 1000px) {
        font-size: 10px;
        padding: 0;
    }

    &>table {
        border: 1px solid #E2E2E2;
        border-collapse: collapse;
        text-align: center;
        table-layout: fixed;
        width: 100%;

        &>tbody {
           &>tr {
                border: 1px solid #E2E2E2;
                &>th {
                    border: 1px solid #E2E2E2;
                }
                &>td {
                    border: 1px solid #E2E2E2;
                    &:nth-child(1) {
                        word-break: break-all;
                    }
                    &>.myAccountBookingItemChildrenTreatment {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
        
                        &>.myAccountBookingItemChildrenTreatmentImg {
                            width: 5em;
                            height: 5em;
                            object-fit: cover;
                            border-radius: 5px;

                            @media only screen and (max-width: 1000px) {
                                width: 3em;
                                height: 3em;
                            }
                        }
                    }
                }
            }
        }
    }

    &>.myAccountBookingItemTitle {
        text-decoration: underline;
        font-family: 'PoppinsRegular';
        text-transform: uppercase;
    }
}

.myAccountBookingItemChildrenAction {
    font-family: 'PoppinsLight';
}

.myAccountBookingRescheduleModalCont {
    display: flex;
    flex-direction: column;
    font-family: 'PoppinsRegular';
    text-align: center;
    font-size: 15px;
    color: #593B26;

    .important {
        font-family: 'PoppinsBold';
    }

    &>span:nth-child(5) {
        padding-left: 7em;
        padding-right: 7em;
        font-size: initial;

        @media only screen and (max-width: 1000px) {
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    &>div {
        background: #B2A08A;
        color: #fff;
        border-radius: 5px;
        padding: 10px;
        width: fit-content;

        &>p {
            width: 100%;
            font-size: 13px;
            font-family: 'PoppinsLight';
    
            &>span {
                font-family: 'PoppinsSemiBoldItalic'
            }
    
            &>span:nth-child(1) {
                color: #593B26;
            }
        }
    }
}

.myAccountBookingItemChildren {
    @media only screen and (max-width: 1000px) {
        font-size: 12px;
    }
}

.copyId {
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}