.textInputDiv
{
    text-align: start;
    width: 100%;

    &>.textInputLabel {
        text-transform: uppercase;
        font-size: 15px;
        font-family: 'OpenSansRegular';
    }

    &>.MuiInputBase-root {
        border-radius: 0px !important;
        background: #9D5C58 !important;
        color: #fff !important;

        &>.MuiInputAdornment-root {
    
            &>.MuiSvgIcon-root {
                fill: #fff !important;
            }
            
            &>.MuiTypography-root {
                color: #fff !important;
            }

            &>.MuiIconButton-root {
                &>.MuiSvgIcon-root {
                    fill: #fff !important;
                }
            }
        }
    }
    
    &>.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }
    
    &>.MuiInputBase-input {
        background: #9D5C58 !important;
        color: #fff !important;
    }
    
    &>.MuiInputBase-input input {
        color: #fff !important;
    }
    
    &>.MuiInputBase-input::placeholder {
        color: #fff !important;
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #9D5C58 inset !important;
    }
    
    &>.MuiInputAdornment-positionStart {
        background: #AB7C7A !important;
        height: 100% !important;
        display: flex;
        justify-content: center;
        padding: 1em; 
        margin-left: -.9em;
        margin-right: 1em !important;
    }
    
    &>.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }
}