.treatmentCard {
    user-select: none;
}

.treatmentImages {
    background: #f4eee9;
    position: relative;
    width: 460px;
    height: 600px;
    flex: 1;
    margin: 20px;
    overflow: hidden;
    transition: all 1s;

    @media only screen and (max-width: 1450px) {
        width: 400px;
    }

    @media only screen and (max-width: 1250px) {
        width: 300px;
        height: 400px;
    }

    @media only screen and (max-width: 1000px) {
        margin: .5em;
    }

    img {
        opacity: 0.8;
        position: relative;
        vertical-align: top;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 1s;
        filter: brightness(70%);
    }

    .treatmentDetails {
        z-index: 1;
        position: absolute;
        top: 0;
        color: #fff;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;

        &>.treatmentTitle {
            text-align: center;
            font-size: 3em;
            text-transform: uppercase;
            font-weight: 300;
            margin-top: 50%;
            transition: all 1s;
            font-family: 'SourceSerifRegular';
            width: 80%;

            @media only screen and (max-width: 1000px) {
                font-size: 1em;
            }
        }

        p {
            text-align: center;
            font-size: 14px;
            margin: 30px 40px 0 40px;
            opacity: 0;
            text-align: justify;
            display: block;
            transition: all 1s;
            font-family: 'PoppinsRegular';
            color: #fff;

            @media only screen and (max-width: 1000px) {
                display: none;
            }
        }

        .more {
            text-align: center;
            transition: all 1s;

            .read-more {
                text-decoration: none;
                color: #fff;
                opacity: 0;
                text-transform: uppercase;
                font-family: 'PoppinsSemiBold';
                font-size: 13px;

                @media only screen and (max-width: 1000px) {
                    font-size: 11px;
                }
            }
        }
    }
}

.treatmentImages:hover {

    cursor: pointer;
    transform: scale(1.1);

    img {
        opacity: 1;
    }

    .treatmentDetails .treatmentTitle {
        transform: translateY(-50px);
    }

    .treatmentDetails p {
        opacity: 1;
        transform: translateY(-50px);
    }

    .more {
        transform: translateY(-20px);
        .read-more {
            opacity: 1;
        }
    }
}

@media (max-width: 1080px) {
    .treatmentImages {
        flex: 100%;
        max-width: 480px;
    }
}

@media (max-width: 400px) {
    .treatmentImages .treatmentDetails p {
        font-size: 16px;
    }
}