.singleProductContainer {
    max-width: 1560px;
    width: 100%;
    margin: auto;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    &>.MuiBox-root {
        @media only screen and (max-width: 1000px) {
            margin: 60px 0 40px 0;
        }
    }

    .breadcrumbsDiv {
        ol {
            li {
                a {
                    color: #b2a08b;
                    text-decoration: none;
                    font-family: "PoppinsRegular";

                    @media only screen and (max-width: 1000px) {
                        font-size: 14px;
                    }
                }
    
                .primaryBreadcrumbs {
                    color: #5d3b23;
                    font-family: "PoppinsSemiBold";

                    @media only screen and (max-width: 1000px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .singleProductContentDiv {
        display: grid;
        grid-template-columns: 5fr 5fr;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
        flex-direction: row;
        margin: 0 auto;

        @media only screen and (max-width: 1000px) {
            grid-template-columns: repeat(1, 1fr);
            padding: 2em 4em 0 4em;
        }

        @media only screen and (max-width: 580px) {
            padding: 2em;
        }

        &>.carouselContent {
            padding: 2em;

            @media only screen and (max-width: 1000px) {
                padding: 0;
            }
        
            .carousel {

                .slider {
                    height: auto;
                }
                
                &>.thumbs-wrapper {
                    padding: 0;
                }

                .thumbs {
                    @media only screen and (max-width: 580px) {
                        padding: 0;
                    }
                }

                .thumb {
                    width: 80px !important;
                    height: 80px !important;
                    
                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .slider {
                    @media only screen and (max-width: 1000px) {
                        height: auto;
                    }
                }
        
                .slide {
                    cursor: zoom-in;
        
                    img {
                        width: 90%;
                        vertical-align: top;
                        border: 0;
                        height: 700px;
                        object-fit: cover;
                        padding-bottom: 0px;

                        @media only screen and (max-width: 1000px) {
                            height: auto;
                        }
                    }
                }
            }
        }
        
        .carousel .thumb.selected, .carousel .thumb:hover {
            border: 3px solid #b2a08b !important;
        }

        &>.singleProductContent {
            display: flex;
            flex-direction: column;
            height: auto;
            padding: 2em;

            @media only screen and (max-width: 1000px) {
                padding: 2em 3em;
            }
        
            &>.contentHeader {
                font-size: 40px;
                margin-bottom: 10px;
                color: #5d3b23;
                font-family: "SourceSerifSemiBold";

                @media only screen and (max-width: 1000px) {
                    font-size: 30px;
                }

                &>.capacityDetails {
                    font-size: 18px;
                    opacity: .8;
                    font-family: "PoppinsSemiBold";

                    @media only screen and (max-width: 1000px) {
                        font-size: 13px;
                    }
                }
            }

            &>.contentDetails {
                color: #5d3b23;
                font-family: "PoppinsRegular";
                margin-bottom: 40px;

                @media only screen and (max-width: 1000px) {
                    margin-bottom: 20px;
                }

                &>.descriptionDetails {
                    font-size: 16px;
                    line-height: 1.5rem;
                    text-align: justify;
                    margin-bottom: 30px;

                    @media only screen and (max-width: 1000px) {
                        font-size: 12px;
                        line-height: 1rem;
                    }
                }

                &>.priceDetails {
                    font-family: "PoppinsSemiBold";
                    font-size: 22px;
                    opacity: .8;
                    
                    @media only screen and (max-width: 1000px) {
                        font-size: 13px;
                    }
                }
            }

            .accordionOuter {
                background-color: transparent;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                box-shadow: none !important;
                position: unset !important;
                border-bottom: 1px solid #b2a08b;

                @media only screen and (max-width: 1000px) {
                    margin: 0;
                }

                &>.MuiAccordionSummary-root {
                    padding: 0 !important;

                    .accordionTitle {
                        font-family: "PoppinsSemiBold";
                        font-size: 20px;
                        color: #5d3b23;

                        @media only screen and (max-width: 1000px) {
                            font-size: 14px;
                        }
                    }
                }

                .accordionContent {
                    font-family: "PoppinsRegular";
                    font-size: 15px;
                    line-height: 1.5rem;
                    color: #5d3b23;
                    margin-bottom: 5px;

                    @media only screen and (max-width: 1000px) {
                        font-size: 12px;
                        line-height: 1rem;
                        margin-bottom: 2px;
                    }
                }
            }
        }
    }
}