.bookingItemMainDiv {
    display: grid;
    grid-template-columns: 1fr 9fr;
    align-items: center;
    justify-items: flex-start;
    font-family: 'PoppinsRegular';
    color: #5d3b23;
    margin: 1em;

    &>.bookingItemImgDiv {
        &>img {
            width: 100px;
            height: 100px;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    &>.bookingItemContDiv {
        display: flex;
        flex-direction: column;
        margin-left: .5em;

        &>span {
            margin-bottom: .3em;
        }

        &>.bookingItemContTitle {
            font-family: 'PoppinsBold';
            font-size: 1.1em;
        }

        &>.bookingItemContPrice {
            font-family: 'PoppinsSemiBold';
            font-size: 1.8em;
        }
    }
}