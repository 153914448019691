.locationDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    color: #5d3b23;  

    @media only screen and (max-width: 1000px) {
        flex-direction: column;
    }

    &>.locationContactDiv {
        width: 50%;
        height: 100vh;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media only screen and (max-width: 1000px) {
            width: 100%;
            height: 50vh;
        }

        &>.locationContactTitle {
            font-size: 40px;
            font-family: 'SourceSerifSemiBold';
            font-weight: 700;

            @media only screen and (max-width: 1000px) {
                font-size: 30px;
            }
        }

        &>.contactPersonDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            width: 50%;
            text-align: left;

            @media only screen and (max-width: 1000px) {
                width: 80%;
            }

            &>.locationButtonDiv {
                font-family: 'OpenSansRegular';
                font-size: 16px;
                width: fit-content;
                display: grid;
                grid-template-columns: 1fr auto;
                gap: 1em;

                @media only screen and (max-width: 1000px) {
                    font-size: 14px;
                }

                &>.locationPhone {
                    text-align: left;
                    &>.locationPhoneOwner {
                        font-family: 'OpenSansSemiBold';
                    }
                }

                &>.locationAddress {
                    text-transform: capitalize;
                    text-align: left;
                }
            }
        }


    }

    &>.locationMapDiv {
        width: 50%;
        height: 100vh;
        padding: 20px;
        display: grid;
        place-items: center;        
        @media only screen and (max-width: 1000px) {
            width: 100%;
            height: 50vh;
        }
        &>.locationMap {
            border: none;
            border-radius: 5px;
            width: 90%;
            height: 400px;

            @media only screen and (max-width: 1000px) {
                height: 250px;
            }
        }
    }
}