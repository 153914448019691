.basicTextInputDiv
{
    text-align: start;
    width: 100%;
    user-select: none;

    &>.basicTextInputLabel {
        text-transform: uppercase;
        font-size: 15px;
        font-family: 'OpenSansRegular';
    }
}

.MuiInputBase-root {
    border-radius: 0px !important;
}