.paymentStatusDiv {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;

    &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'OpenSansRegular';
    }
}