.homeProductsContainer {
    background-color: #f4eee9;
    width: auto;
    height: auto;
    padding: 20px 0 20px 0;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    @media only screen and (max-width: 1000px) {
        padding: 0;
        height: 100vh;
    }

    &>.homeProductsDiv {
        width: 100%;
        height: 100%;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 20px;
        position: relative;

        @media screen and (max-width: 1000px) {
            width: 100vw;
            margin: 0;
            height: 100vh;
        }
    
        &>h2 { 
            font-family: "SourceSerifRegular";
            letter-spacing: 5px;
            -webkit-text-stroke: 1.5px #5d3b23;
            color: #5d3b23;  

            @media only screen and (max-width: 1000px) {
                font-size: 40px;
            }
        }

        
        .productGrid {
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            place-items: center;
            gap: .1em;

            @media screen and (max-width: 1200px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            &>.product-items {
                cursor: pointer;
                margin: 1em;
                text-decoration: none;
                border-radius: 10px;
                transform: scale(1);
            
                @media screen and (max-width: 1000px) {
                    margin: .5em;
                }
            
                &>.product {
                    padding: 10px;
                    background: #f5f5f5;
                    position: relative;
                    border-radius: 10px;
                    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3); 

                    &>.productImgDiv {
                        background: #fff;
                        padding: 50px;
                        position: relative;
                        width: 200px;
                        height: 200px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-size: 300px 300px;
                        background-repeat: no-repeat;
                
                        @media screen and (max-width: 1000px) {
                            width: 50px;
                            height: 50px;
                            background-size: 150px 150px;
                        }
                
                        &>.productImg {
                            padding: 20px;
                            width: 200px;
                            max-height: 100%;
                            object-fit: cover;
                            transition: all 0.5s ease;
                            border-radius: 30%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            z-index: 2;
                
                            @media screen and (max-width: 1000px) {
                                width: 80px;
                                height: 80px;
                            }
                        }
                
                        &:hover .productImg {
                            transform: translate(-13px, -10px);
                        }
                
                        &:hover .productBg {
                            left: 20%;
                            top: 20%;
                            opacity: .8;
                        }
                
                        &:hover .productBg::before {
                            top: -20%;
                            left: 30%;
                            opacity: .8;
                        }
                
                        &:hover .productBg::after {
                            bottom: 10%;
                            left: -20%;
                            opacity: .8;
                        }
                
                        &>.productBg {
                            position: absolute;
                            padding: 20px;
                            width: 150px;
                            height: 150px;
                            border-radius: 30%;
                            background: #9F8189;
                            z-index: 1;
                            left: 10%;
                            top: 10%;
                            opacity: 0;
                            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                
                            @media screen and (max-width: 1000px) {
                                width: 70px;
                                height: 70px;
                            }
                
                            &::before {
                                content: '';
                                position: absolute;
                                top: 60%;
                                left: 20%;
                                width: 80%;
                                height: 80%;
                                z-index: 0;
                                background: #F3ABB6;
                                border-radius: 30%;
                                opacity: 0;
                                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                            }
                
                            &::after {
                                content: '';
                                position: absolute;
                                bottom: -30%;
                                left: 50%;
                                width: 70%;
                                height: 70%;
                                z-index: 0;
                                background: #FFCAD4;
                                border-radius: 30%;
                                opacity: 0;
                                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
                            }
                        }
                    }
                }
            }
        }

        &>.viewMoreButton {
            margin-top: 1em !important;
            background: transparent !important;
            color: #5d3b23 !important;
            background: #b2a08b !important;
            font-family: "PoppinsSemiBold" !important;
        }
    }
}