.userVerificationDiv
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    &>.userVerificationContDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: 'PoppinsRegular';

        &>.userVerificationForm {
            width: 25vw;
            border-radius: 10px;  
            background: #F7F7F7;
            padding: 5em ;
            text-align: center;
            position: relative;

            &>.errorMsg {
                font-size: 12px;
                color: red;
                font-family: 'PoppinsRegular';
            }

            &>.userVerificationTitle {
                width: 100%;
                position: absolute;
                top: 5%;
                left: 0;
                letter-spacing: .05em;
                font-family: 'PoppinsBold';
                text-transform: uppercase;
                color: #A86C6B;
                font-size: 1.5em;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &>.userVerificationSubTitle {
                    width: 100%;
                    letter-spacing: .05em;
                    font-family: 'PoppinsBold';
                    text-transform: uppercase;
                    color: #A86C6B;
                    font-size: .5em;
                }
            }

            @media only screen and (max-width: 768px) {
                width: 45vw;
            }
        }
    }

    &>.userVerificationCompletedDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'PoppinsRegular';

        &>.icon {
            font-size: 10em;
            color: #A86C6B;
        }

        &>.title, .subtitle {
            &>span {
                font-family: 'PoppinsBold';
            }
        }
    }
}