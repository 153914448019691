.modalDiv 
{
    display: flex;
    justify-content: center;
    align-items: center;

    &>.modalContDiv 
    {
        background: #fff;
        z-index: 2;
        border-radius: 5px;
        padding: 3em;
        outline: none;
        box-shadow: 5px 5px 5px rgba(0,0,0,.1);
        position: relative;
        width: 50vw;

        &>.modalContTitle {
            font-family: 'OpenSansBold';
            white-space: normal;
        }

        &>.modalContCloseIcon 
        {
            position: absolute;
            top: .5em;
            right: .5em;
            font-size: 2em;
            cursor: pointer;
            opacity: .5;
            transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {
                opacity: 1;
            }
        }
    }
}

.modalRootDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    &>.modalDiv {
        width: auto;
        margin: 1.75rem;
    }

    &>.modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f6f4f0;
        z-index: 9999;
        border-radius: 5px;

        .modalHeader {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-end;
            
            &>svg {
                padding: 10px;
                color: #b2a08b;
                opacity: 1;
                cursor: pointer;
            }
        }

        .modalImageDiv {
            padding: 20px;
            img {
                max-height: 85vh;
                object-fit: contain;
                width: 100%;
            }
        }
    }
}