.bottom-carousel {

    &>.oneImageSlider {
        width: 320px;
    }

    padding: 20px;

    &>.carousel-heading {
        text-transform: uppercase;
        font-size: 24px;
        border-bottom: 1px solid #b2a08b;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        font-family: "SourceSerifSemiBold";
        color: #5d3b23;

        @media only screen and (max-width: 1000px) {
            font-size: 18px;
        }
    }

    .slick-prev-custom
    {
        position: absolute;
        top: -2.2em;
        right: 2.16em !important;
        left: auto !important;
        background: transparent;
        font-size: 20px;
    }

    .slick-next-custom
    {
        position: absolute;
        top: -2.2em;
        right: .4em !important;
        left: auto !important;
        background: transparent;
        font-size: 20px;
    }
}

