.navigationBarGrid {
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;

    @media only screen and (max-width: 1000px) {
        padding: 50px;
        flex-direction: column !important;
    }

    div {
        color:#5d3b23;
        display: flex !important;
        flex-wrap: nowrap;
        flex-direction: column;
        flex-basis: 15%;
        justify-content: flex-start;

        .footerTitle {
            width: fit-content;
            margin-bottom: 15px;
            position: relative;
            font-size: 17px;
            font-family: "PoppinsBold" !important;
        }

        div {
            .whatsappCont {
                color: white;
            }

        }
                
        .h5 {
            width: fit-content;
            margin-bottom: 15px;
            position: relative;
            font-size: 17px;
            font-family: "PoppinsSemiBold" !important;

            @media only screen and (max-width: 1000px) {
                margin: 0 0 10px 0;
                font-size: 15px;
            }
        }

        * {
            font-family: "PoppinsRegular" !important;
        }
         
        a {
            color: #5d3b23;
            margin-bottom: 7px;
            padding: 0;
            text-align: left;
        }
    }
}

.navigation-AboutUs {
    font-size: 14px !important;
    text-align: left !important;
}

.whatsappButton {
    text-align: center;
    width: fit-content;
}

.social-box {
    flex-direction: row !important;
    margin-top: 20px;
}

.navigationBox {  

    width: 70%;  
    font-size: 14px;

    @media only screen and (max-width: 1000px) {
        font-size: 13px;
    }

    @media only screen and (max-width: 1000px) {
        width: auto;
    }
        
    & > .navigationBoxLink {
        width: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        text-transform: capitalize;
        position: relative;

        @media only screen and (max-width: 1000px) {
            font-size: 13px;
        }
    }

    
    ::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #b2a08b;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    :hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}
