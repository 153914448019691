.paymentResultDiv {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        background-color: #EEE5DC;
        width: 50vw;
        height: 50vh;
        border-radius: 40px;
        color: #5A3A20;
        padding: 20px;

        &>.paymentResultSuccessTitle {
            font-family: 'Javacom';
            font-size: 5em;
            padding-bottom: 1em;
            text-align: center;

            @media only screen and (max-width: 1000px) {
                font-size: 3em;
            }
        }

        &>.paymentContSubTitle {
            font-family: 'OpenSansBold';
            font-size: 2em;
            padding-bottom: .5em;
            text-align: center;

            @media only screen and (max-width: 1000px) {
                font-size: 1.5em;
            }
        }

        &>.paymentIdCont {
            font-family: 'OpenSansExtraBold';
            font-size: 1.8em;

            @media only screen and (max-width: 1000px) {
                font-size: 1.3em;
            }

            &>.paymentIdSpan {
                font-family: 'OpenSansRegular';
            }
        }

        &>.paymentCont {
            font-family: 'OpenSansRegular';
        }

        &>.paymentRedirection {
            font-family: 'OpenSansExtraBold';

            @media only screen and (max-width: 1000px) {
                font-size: 1em;
            }

            &>span {
                cursor: pointer;
                text-decoration: underline;
                color: #C6B2A4;
            }
        }

        &>.paymentResultFailed {
            width: 10em;
            padding-bottom: 1em;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &>svg {
                &>.svgG {
                    stroke: none; 
                    stroke-width: 0; 
                    stroke-dasharray: none; 
                    stroke-linecap: butt; 
                    stroke-linejoin: miter; 
                    stroke-miterlimit: 10; 
                    fill: none; 
                    fill-rule: nonzero; 
                    opacity: 1;

                    &>.svgGC1 {
                        stroke: none; 
                        stroke-width: 1; 
                        stroke-dasharray: none; 
                        stroke-linecap: butt; 
                        stroke-linejoin: miter;
                        stroke-miterlimit: 10; 
                        fill: #5A3A20; 
                        fill-rule: nonzero; 
                        opacity: 1;
                    }

                    &>.svgGC2 {
                        stroke: none; 
                        stroke-width: 1; 
                        stroke-dasharray: none; 
                        stroke-linecap: butt; 
                        stroke-linejoin: miter; 
                        stroke-miterlimit: 10; 
                        fill: #5A3A20; 
                        fill-rule: nonzero; 
                        opacity: 1;
                    }

                    &>.svgGP {
                        stroke: none; 
                        stroke-width: 1; 
                        stroke-dasharray: none; 
                        stroke-linecap: butt; 
                        stroke-linejoin: miter; 
                        fill: #5A3A20; 
                        fill-rule: nonzero; 
                        opacity: 1; 
                        stroke-miterlimit: 23;
                    }
                }
            }

            &>.svgTear {
                position: absolute;
                z-index: 2;
                width: 10em;
                animation: tear 1s infinite alternate;
                transition: ease;
            }

            @keyframes tear {
                0% {
                    top: 0;
                }
                100% {
                    top: 5px;
                }
            }
        }

        @media only screen and (max-width: 1000px) {
            width: 90vw;
        }
    }
}