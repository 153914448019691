@import './assets/fonts/fonts.scss';
@import './assets/color/palette.scss';

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    background: #f6f4f0;
}

#root {
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden ;
}

:root {
    --treatment-selection-height: 100px;
    --header-height: 0px;
    --side-menu-height: 0px;
}

::-webkit-scrollbar {
    width: 5px;
    height: 12px;
}

::-webkit-scrollbar-track {
    border: none;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background:  #B4A28E;
}