.copyright-words {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "PoppinsRegular";
    color: #5d3b23;

    @media only screen and (max-width: 1000px) {
        font-size: 12px !important;
    }

    @media only screen and (max-width: 480px) {
        font-size: 10px !important;
    }

    svg {
        width: 1rem;
    }
}

.copyright-wrapper {
    min-height: 40px !important;
}