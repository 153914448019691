.aboutUsContainer {
    background: #f6f4f0;
    margin-top: 80px;
    width: 100vw;
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    h3 {
        font-size: 30px;
        font-family: "SourceSerifSemiBold";

        @media only screen and (max-width: 1000px) {
            font-size: 14px;
        }
    }

    .missionContainer, .visionContainer {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        &>.vision {
            @media only screen and (max-width: 1000px) {
                flex-direction: column-reverse !important;
                align-items: center;
            }
        }

        &>.missionContent {
            display: flex;
            width: 80%;
            margin: 0 auto;
            padding: 50px;
            justify-content: center;
            background: #f4eee9;

            @media only screen and (max-width: 1000px) {
                flex-direction: column;
                align-items: center;
                padding: 0px !important;
                width: 100% !important;
            }

            &>.MuiBox-root {
                &>.MuiTabs-root {
                    .MuiButtonBase-root {
                        @media only screen and (max-width: 1000px) {
                            font-size: 10px !important;
                        }

                        @media only screen and (max-width: 450px) {
                            padding: 0 !important;
                        }
                    }
                }

                &>div {
                    &>.tabPanel {
                        @media only screen and (max-width: 1000px) {
                            padding: 40px;
                            width: auto !important;
                        }
                    }
                }

                @media only screen and (max-width: 1000px) {
                    margin-top: 50px;
                    width: 100%;
                }
            }
    

            .Mui-selected {
                color:  #5d3b23;
            }

            .MuiTabs-indicator {
                background-color:  #b2a08b;
            }
            

            img {
                width: 300px;
                height: auto;

                @media only screen and (max-width: 1000px) {
                    padding-top: 20px;
                    width: 150px
                }
            }

            button {
                font-family: "PoppinsSemiBold";
            }

            .missionInfo {
                font-size: 16px;
                font-weight: 700;
                font-family: "PoppinsRegular";

                @media only screen and (max-width: 1000px) {
                    font-size: 10px;
                }
            }
        }
    }
}

.tabPanel {
    display: flex;
    text-align: justify;
    justify-content: center;
}
