.adminPanelOuterDiv {
    .adminPanelDiv {
        width: 100vw;
        height: 100vh;
        position: relative;
    
        &>.adminPanelContDiv {
            width: 100%;
            height: 100%;
        }
    }

    &>.MuiBox-root>.MuiPaper-root>.MuiToolbar-root {
        width: 100vw !important;
    }

    &>.adminPanelOuterDivActions {
        position: absolute !important;
        top: 10px;
        right: 10px !important;
        z-index: 1202;
        width: fit-content;
        height: fit-content;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1em;
        &>.actionBtn{
            &>svg {
                fill: #fff !important;
            }
        }
    }
}