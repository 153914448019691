.dashboardCard {
    background: #fff;
    box-shadow: 5px 5px 5px rgba(0,0,0,.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em;
    border-radius: 5px;
    padding: 60px 20px;
    font-family: 'PoppinsRegular';
    position: relative;
    overflow: hidden;

    &>span:nth-child(1) {
        font-size: 17px;
    }

    &>span:nth-child(2) {
        font-family: 'PoppinsBold';
        font-size: 5em;
    }

    &>span:nth-child(3) {
        font-family: 'PoppinsLight';
        font-size: 13px;
    }

    &>span:nth-child(4) {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        opacity: .5;
        transition: .3s;
        z-index: 10;

        &:hover {
            opacity: .8;
        }
    }

    &>.bgIcon {
        position: absolute;
        bottom: -70px;
        right: -70px;
        opacity: .08;

        &>svg {
            font-size: 15em;
        }
    }

    &>.bgIconLogo {
        width: 15em !important;
    }
}