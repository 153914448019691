.testimonialContainer {
    background-color: #f4eee9;
    padding: 20px 0 20px 0;

    &>.testimonialDiv {
        width: 90%;
        margin: 50px auto;
        overflow: hidden;

        &>.testimonialContent {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            flex-direction: column;
            color: #5d3b23;
        
            .testimonialTitle {
                text-align: center;
                h2 {
                    font-size: 3.75em;
                    letter-spacing: 5px;
                    font-family: "SourceSerifRegular";

                    @media only screen and (max-width: 1000px) {
                        font-size: 40px;
                    }
                }
        
                p {
                    font-size: 18px;
                    font-family: "PoppinsRegular";
                    text-align: center;
                }
            }

            &>.testimonialGrid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: .5em;
                transition: .3s;
            
                @media only screen and (max-width: 1000px) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
        
    }
}