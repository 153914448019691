.treatmentContainer {
    width: 65%;
    margin: 0 auto;
    padding: 20px;

    p {
        font-family: "SourceSerifBold";
        color: #5d3b23;
        font-size: 30px;
        margin: 0;
    }
}

.treatmentDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    @media only screen and (max-width: 1000px) {
        flex-direction: column
    }
}

.treatmentListPage {
    width: 100% !important;
    background-color: #f4eee9;
    margin: 120px auto 0 !important;
}