.iconTransparentButton {
    border: 1px solid rgba(0,0,0,.3);
    border-radius: 5px;
    font-family: 'OpenSansSemiBold';
    text-transform: uppercase;
    letter-spacing: .2em;
    user-select: none;
    cursor: pointer;
    padding: .5em;
    color: rgba(0,0,0,.3);
    opacity: 1;
    position: relative;
    &:hover {
        opacity: .9;
    }
}

.iconTransparentButtonDisabled {
    cursor: not-allowed;
    opacity: .5;
}

.iconTransparentButtonSubmitting {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.iconTransparentCont {
    display: flex;
    flex-direction: column;
    text-align: center;

    &>.icon {
        margin: auto;
        padding: auto;
        &>svg {
            font-size: 40px !important;
        }
    }

    &>.label {
        font-size: 10px;
    }
}