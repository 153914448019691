.gridTableImgDiv {
    width: 100%;
    height: fit-content;

    &>.gridTableImg {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.adminTreatmentDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &>.adminTreatmentContDiv {
        width: 80%;
        padding: 20px;
        .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus {
            outline: none !important;
        }
    }
}

.dataGrid {
    height: 400px;
    width: 100%;
}

.actionTab {
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.infoLabel {
    font-family: 'OpenSansRegular';
    font-size: 13px;
    color: #EC5252;
}